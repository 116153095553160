/* General Styling */
@import "~@fortawesome/fontawesome-free/css/all.css";

.drivers-container {
  padding: 20px;
  font-family: "Roboto", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
}

.drivers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.drivers-header h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  color: #222;
}

.drivers-actions button {
  margin-left: 10px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.bulk-upload-button {
  background-color: #6c757d;
  color: #fff;
}

.bulk-upload-button:hover {
  background-color: #5a6268;
}

.add-driver-button {
  background-color: #007bff;
}

.add-driver-button:hover {
  background-color: #0056b3;
}

.drivers-table {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
/* Add styles for the edit and delete buttons */
.drivers-table button {
  padding: 8px 12px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Edit button styles */
.drivers-table .edit-button {
  background-color: #4caf50; /* Green color for Edit */
  color: white;
}

.drivers-table .edit-button:hover {
  background-color: #45a049;
  transform: scale(1.05); /* Slight zoom effect */
}

/* Delete button styles */
.drivers-table .delete-button {
  background-color: #f44336; /* Red color for Delete */
  color: white;
}

.drivers-table .delete-button:hover {
  background-color: #e53935;
  transform: scale(1.05); /* Slight zoom effect */
}

.save-button {
  background-color: #4caf50; /* Green */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}

.save-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336; /* Red */
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.table-controls {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background-color: #f8f9fa;
}

.table-option {
  margin-right: 20px;
  cursor: pointer;
  color: #007bff;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.table-option:hover {
  color: #0056b3;
}

.search-input {
  margin-left: auto;
  padding: 8px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

thead {
  background-color: #e9ecef;
}

th,
td {
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
}

th {
  background-color: #f1f1f1;
  font-weight: 600;
  color: #444;
}

.no-rows {
  text-align: center;
  color: #999;
}

.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
}

.pagination-controls button {
  border: none;
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

/* Add Driver Form Styling */
.add-driver-form {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.add-driver-form h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  font-size: 1rem;
  color: #555;
  font-weight: 500;
  margin-bottom: 8px;
}

.form-field input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-field input:focus {
  border-color: #007bff;
  outline: none;
}

.form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.form-actions button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.form-actions button[type="button"] {
  background-color: #007bff;
}

.form-actions button[type="button"]:hover {
  background-color: #0056b3;
}

.form-actions button[type="cancel"] {
  background-color: #dc3545;
}

.form-actions button[type="cancel"]:hover {
  background-color: #c82333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .drivers-container {
    padding: 15px;
  }

  .drivers-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .drivers-header h1 {
    font-size: 1.6rem;
  }

  .drivers-actions button {
    width: 100%;
    margin: 10px 0;
  }

  .table-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .table-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
  }

  .pagination-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .drivers-container {
    padding: 10px;
  }

  .drivers-header h1 {
    font-size: 1.4rem;
  }

  .drivers-actions button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .table-controls .table-option {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  .search-input {
    width: 100%;
  }
}

/* CSS for deletion */
.delete-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.popup-actions button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.popup-actions button:first-of-type {
  background-color: red;
  color: white;
}

.popup-actions button:last-of-type {
  background-color: gray;
  color: white;
}
