/* Main container */
.users-roles-container {
  padding: 20px;
  background-color: #f4f5f7;
  font-family: "Arial", sans-serif;
}

/* Tab menu */
.tab-menu {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  color: #333333;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
}

.tab.active {
  background-color: #007bff;
  color: #ffffff;
  border-color: #007bff;
  box-shadow: 0 2px 5px rgba(0, 123, 255, 0.4);
}

.tab:hover {
  background-color: #f0f0f0;
}

/* Content Section */
.content-section {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  margin: 0;
}

.add-user-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
}

.add-user-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Table */
.table {
  margin: 20px 0;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-weight: bold;
  color: #555555;
  border-bottom: 2px solid #ddd;
  padding: 10px 0;
  background-color: #f9f9f9;
}

.table-header span {
  text-align: center;
}

.table-body {
  text-align: center;
  padding: 20px;
  color: #999999;
  font-style: italic;
}

.table-body p {
  margin: 0;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 20px;
}

.pagination p {
  margin: 0;
  font-size: 14px;
  color: #555555;
}

.pagination-controls button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.2s;
}

.pagination-controls button:hover {
  text-decoration: underline;
  color: #0056b3;
}

/* Dropdown styles for "Add User" form */
.add-user-dropdown {
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.add-user-dropdown h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

.add-user-dropdown .form-group {
  margin-bottom: 15px;
}

.add-user-dropdown label {
  display: block;
  font-size: 14px;
  color: #333333;
  margin-bottom: 8px;
}

.add-user-dropdown .input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.add-user-dropdown .input-field:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.add-user-dropdown .form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.add-user-dropdown .save-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
}

.add-user-dropdown .save-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.add-user-dropdown .cancel-btn {
  background-color: #dc3545;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
}

.add-user-dropdown .cancel-btn:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .table-header {
    grid-template-columns: repeat(3, 1fr);
  }

  .pagination {
    flex-direction: column;
    gap: 10px;
  }

  .add-user-dropdown {
    max-width: 100%;
  }
}
