.vehicle-service-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.chart-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold; /* Makes the subtitle stand out */
  color: #333; /* Darker color for better visibility */
}

.table-container {
  overflow-x: auto; /* Add horizontal scrolling for smaller screens */
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.data-table th,
.data-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: #333; /* Dark background color */
  color: #fff; /* Light text color for contrast */
  font-weight: bold;
  font-size: 16px; /* Slightly larger font size for visibility */
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}

.data-table td {
  white-space: nowrap; /* Prevent text from wrapping */
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .data-table th,
  .data-table td {
    font-size: 12px;
    padding: 8px;
  }

  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
  }
}
