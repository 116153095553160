/* Component Specific  CSS */

/* NAVBAR */

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0f0f1f;
  padding: 10px 20px;
  color: white;
}
/* logo */
.navbar-logo img {
  height: 75px;
}
/* Burger icon for mobile navbar */
.burger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}
.burger-bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: transform 0.3s ease;
}

/* redirecting links */
.navbar-links,
.login-navbar-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}
/* redirecting links anchor tag */
.navbar-links li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
}
/* buttons to be clicked in order to redirect */
.nav-button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 500;
}
/* Responsiveness of Navbar */
/* Responsive Styles */
@media (max-width: 768px) {
  .burger-menu {
    display: flex;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 20px;
    background-color: #0f0f1f;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 200px;
    gap: 10px;
  }

  .navbar-links.active {
    display: flex;
  }

  .nav-button {
    text-align: left;
    width: 100%;
  }
}

/* HERO SECTION */

/* full screen bg and centering  */
.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  position: relative;
  overflow-x: hidden;
}
/* to add darkness over bg image */
.hero-overlay {
  background: rgba(0, 0, 0, 0.6); /* Transparent dark overlay */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
/* our moto */
.hero-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.2;
}
/* cards in heros section */
.hero-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
.hero-card {
  background: rgba(52, 129, 237, 0.7);
  border-radius: 10px;
  padding: 20px 30px;
  width: 300px; /* Adjust size */
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}
.hero-card:hover {
  transform: translateY(-10px);
}
/* text inside the hero-card */
.hero-card h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.hero-card p {
  font-size: 1rem;
  margin-bottom: 15px;
  color: #dcdcdc;
}
/* links in hero section */
.hero-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
}
.hero-link:hover {
  text-decoration: underline;
}
/* responsive */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem; /* Reduce title size */
    margin-bottom: 20px;
  }

  .hero-card {
    width: 250px; /* Reduce card width */
    padding: 15px 20px;
  }

  .hero-card h2 {
    font-size: 1.25rem; /* Reduce card title size */
  }

  .hero-card p {
    font-size: 0.9rem; /* Reduce card text size */
  }
}

/* Stack cards vertically for small screens */
@media (max-width: 480px) {
  .hero-cards {
    flex-direction: column; /* Stack cards */
    gap: 15px; /* Smaller gap between cards */
  }

  .hero-title {
    font-size: 1.8rem; /* Further reduce title size */
  }

  .hero-card {
    width: 90%; /* Make cards responsive to screen width */
  }
}

/* OUR STORY */

/* story section */
.story-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
  text-align: left;
}

/* dark overlay on background image */
.story-overlay {
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
/* content(text) */
.story-content {
  max-width: 1240px;
  display: flex;
  gap: 40px;
  flex-wrap: wrap; /* Ensures responsiveness */
}
/* title Our Story text basically */
.story-title {
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  text-align: right;
}
.story-title span {
  color: #00aaff; /* Highlight color for "Our" */
}
.story-text {
  flex: 1;
}
.story-subtitle {
  font-size: 1.8rem;
  margin-bottom: 15px;
}
.story-text p {
  font-size: 1.2rem;
  line-height: 1.8;
}
@media (max-width: 768px) {
  .story-title {
    font-size: 2.5rem; /* Adjust title size */
    text-align: center; /* Center-align for better presentation */
  }

  .story-content {
    flex-direction: column; /* Stack content vertically */
    gap: 30px; /* Reduce gap between sections */
  }

  .story-text {
    text-align: center; /* Center-align text for balance */
  }

  .story-subtitle {
    font-size: 1.5rem; /* Reduce subtitle size */
  }

  .story-text p {
    font-size: 1.1rem; /* Slightly reduce paragraph text size */
  }
}

/* Small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  .story-title {
    font-size: 2rem; /* Further reduce title size */
  }

  .story-text p {
    font-size: 1rem; /* Adjust text size for readability */
    line-height: 1.6; /* Adjust line height for compact screens */
  }

  .story-content {
    gap: 20px; /* Smaller gap between sections */
  }
}

/* SERVICES*/

.services-section {
  background-color: #0b132b;
  color: #fff;
  padding: 50px 20px;
  text-align: center;
}
.services-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.services-section p {
  font-size: 1.1rem;
  color: #ccc;
  margin-bottom: 30px;
}
.services-buttons {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.services-buttons button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.services-buttons button.contact {
  background-color: rgba(52, 129, 237, 0.7);
  color: white;
}
.services-buttons button.contact:hover {
  background-color: #0056b3;
}
.services-buttons button.reviews {
  background-color: white;
  color: #0b132b;
}
.services-buttons button.reviews:hover {
  background-color: #ddd;
}
.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}
.services-grid-container {
  display: flex;
  justify-content: center;
  padding: 5vw 10vw;
}
.service-card {
  background-color: rgba(52, 129, 237, 0.7);
  padding: 25px;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 25vw;
  height: 16vw;
  position: relative;
}
.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.service-card h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.service-card p {
  font-size: 1rem;
  color: #e0e0e0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: normal;
  line-height: 1.5;
}
.service-card span {
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: bold;
  color: white;
}

/* MODAL- created after clicking on a service card */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* Modal Content- Text in modal */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.modal-content p {
  color: black;
}
/* Close Button - to handle modal close*/
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* responsiveness */
@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
  }
  .services-section h2 {
    font-size: 2rem;
  }
  .services-section p {
    font-size: 1rem;
  }
  .service-card {
    height: 20vw; /* Adjusted height */
    width: 40vw; /* Adjusted width */
    padding: 20px;
  }
  .service-card h3 {
    font-size: 1.2rem;
  }
  .service-card p {
    -webkit-line-clamp: 4; /* Allow slightly more text */
  }
}

/* Small Screens (e.g., mobile phones) */
@media (max-width: 480px) {
  .services-grid {
    grid-template-columns: 1fr; /* Single-column layout */
    gap: 1rem;
  }
  .services-section h2 {
    font-size: 1.8rem;
  }
  .services-section p {
    font-size: 0.95rem;
  }
  .service-card {
    padding: 15px;
    height: auto; /* Adjusts dynamically based on content */
    width: 90%; /* Fit most of the screen */
    text-align: center;
  }
  .service-card h3 {
    font-size: 1.1rem;
  }
  .service-card p {
    display: none; /* Hide descriptions on mobile */
  }
  .service-card span {
    font-size: 1.3rem;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}

/* TESTIMONIAL/REVIEW SECTION */
.testimonial-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Space between image and text */
  background-color: #0b132b;
  color: white;
  padding: 50px 20px;
  text-align: left;
}
.testimonial-content {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Limit width of text content */
}
.testimonial-feedback {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.testimonial {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 800px;
  margin: 0 auto;
}
.testimonial-author {
  font-size: 1rem;
  color: #ccc;
  margin-bottom: 5px;
}
.testimonial-designation {
  font-size: 0.9rem;
  color: #aaa;
}

.testimonial-organization {
  font-size: 0.9rem;
  color: #aaa;
}

.testimonial-portrait {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.testimonial-portrait img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.author {
  font-size: 1rem;
  margin-top: 10px;
  color: #ccc;
}
.testimonial-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
.testimonial-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  margin-bottom: 1.5rem;
  gap: 2rem;
}
.testimonial-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonial-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%; /* Oval shape */
  border: 2px solid #ddd;
}
.testimonial-content {
  flex: 2;
}
.testimonial {
  font-size: 1.1rem;
  line-height: 1.6;
  color: white;
}
.author {
  font-weight: bold;
  margin-top: 0.5rem;
}
.designation {
  font-size: 0.9rem;
  color: #666;
}

/* Carousel Dots */
.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
.carousel-dots button {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border: none;
  border-radius: 50%;
  background-color: #555;
  cursor: pointer;
  transition: background-color 0.3s;
}
.carousel-dots button.active {
  background-color: #007bff;
}
.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.carousel-dots button {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.carousel-dots button.active {
  background-color: #333;
}
.carousel-dots button:hover {
  background-color: #666;
}
@media (max-width: 768px) {
  .testimonial-container {
    max-width: 90%;
    padding: 0 10px;
    flex-direction: column;
    text-align: center;
  }

  .testimonial {
    font-size: 1.1rem;
  }

  .testimonial-image {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  .author {
    font-size: 0.9rem;
  }

  .designation {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .testimonial-container {
    padding: 0 15px;
    flex-direction: column;
    text-align: center;
  }
  .testimonial {
    font-size: 1rem;
    margin: 0 auto;
    max-width: 90%;
  }

  .testimonial-image {
    width: 80px;
    height: 80px;
    margin-bottom: 25px;
  }

  .author {
    font-size: 0.85rem;
  }

  .designation {
    font-size: 0.75rem;
  }

  .carousel-dots button {
    width: 10px;
    height: 10px;
  }
}

/* Marquee Section - Headline like running line */
.marquee {
  background-color: #eaf2ff;
  color: #007bff;
  padding: 10px 0;
  overflow: hidden;
  position: relative;
}
.marquee-content {
  display: flex;
  gap: 50px;
  white-space: nowrap;
  animation: scroll 15s linear infinite;
  font-size: 1rem;
}
.marquee-item {
  display: inline-block;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* CONTACT FORM SECTION */
.contact-section {
  background-color: #0b132b;
  color: white;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  background-color: #1e2a47;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

/* Image Section */
.contact-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.contact-image {
  flex: 1;
  min-width: 300px;
}

/* Form Section */
.contact-form {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.contact-form p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #ccc;
}
.contact-form .row {
  display: flex;
  gap: 3rem;
}
.contact-form .input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.contact-info {
  margin-bottom: 20px;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info a {
  color: #5bc0de;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Form Inputs */
.contact-form label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  display: block;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #2a3b58;
  color: white;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #5bc0de;
}

textarea {
  resize: none;
}

/* Submit Button */
.submit-button {
  background-color: #5bc0de;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #3a9ec7;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 20px; /* Added padding for better spacing */
  }

  .contact-image {
    min-width: 100%;
    margin-bottom: 20px; /* Add space below the image */
  }

  .contact-form {
    padding: 20px; /* Reduced padding for better alignment */
  }

  .contact-form h2 {
    font-size: 1.5rem;
  }

  .contact-form p {
    font-size: 0.9rem;
  }

  .contact-form .row {
    flex-direction: column;
    gap: 1rem;
  }

  .contact-form .input-group {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .contact-form h2 {
    font-size: 1.25rem;
  }

  .contact-form p {
    font-size: 0.85rem;
  }

  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    font-size: 0.9rem;
  }

  .contact-form .row {
    gap: 1rem;
  }

  .contact-info p {
    font-size: 0.85rem;
  }

  .submit-button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

.footer {
  background-color: #0c0b18; /* Dark background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
}

.footer-text p {
  margin: 0;
}

.footer-icons {
  display: flex;
  gap: 15px; /* Space between icons */
}

.footer-icon-link {
  font-size: 20px; /* Icon size */
  color: white; /* Icon color */
  text-decoration: none;
  transition: color 0.3s;
}

.footer-icon-link:hover {
  color: #00bfff; /* Change color on hover */
}

/*LOGIN PAGE */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 82vh;
  /* background: linear-gradient(135deg, #6a11cb, #2575fc) */
  background: url("../../public/images/login.jpg");
  background-size: cover; /* Ensures the image covers the container */
  background-position: center;
  background-repeat: no-repeat; /* Avoids tiling */
}

/* Login box */
.login-box {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  border: 1px solid white;
}

/* Title */
.login-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: black;
}

/* Form styling */
.login-form .form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

.login-form label {
  display: block;
  font-size: 0.9rem;
  color: black;
  margin-bottom: 0.5rem;
}

.login-form input {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  box-sizing: border-box;
}

.login-form input:focus {
  border-color: #6a11cb;
}

/* Button styling */
.login-button {
  background-color: #6a11cb;
  color: #fff;
  border: none;
  padding: 0.8rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #2575fc;
}

.password-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.password-wrapper input {
  flex: 1;
}

.toggle-password {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.toggle-password:hover {
  color: #000;
}
/* Responsive css */
@media (max-width: 768px) {
  .login-container {
    padding: 20px; /* Adds padding to avoid edge clipping on mobile */
  }

  .login-box {
    padding: 1.5rem;
  }

  .login-title {
    font-size: 1.6rem; /* Adjust font size for smaller screens */
  }

  .login-button {
    padding: 0.7rem;
  }

  .login-form input {
    padding: 0.9rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .login-title {
    font-size: 1.4rem; /* Reduce the title size on smaller screens */
  }

  .login-button {
    font-size: 0.9rem;
  }

  .password-wrapper input {
    font-size: 0.9rem;
  }

  .toggle-password {
    font-size: 1rem; /* Adjust the icon size */
  }
}

/* NotFound.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 77vh;
  background-color: #f4f4f4;
  text-align: center;
  padding: 20px;
}

.not-found-title {
  font-size: 8rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.not-found-message {
  font-size: 2rem;
  color: #555;
  margin: 10px 0;
}

.not-found-description {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 20px;
}

.not-found-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.not-found-button:hover {
  background-color: #218838;
}

/* Responsive not found */
@media (max-width: 768px) {
  .not-found-title {
    font-size: 6rem; /* Smaller title size for tablets */
  }

  .not-found-message {
    font-size: 1.5rem;
  }

  .not-found-description {
    font-size: 1rem;
  }

  .not-found-button {
    font-size: 0.9rem;
    padding: 8px 18px;
  }
}

@media (max-width: 480px) {
  .not-found-title {
    font-size: 5rem; /* Even smaller title size for mobile */
  }

  .not-found-message {
    font-size: 1.3rem;
  }

  .not-found-description {
    font-size: 0.9rem;
  }

  .not-found-button {
    font-size: 0.8rem;
    padding: 6px 16px;
  }
}
