/* Payments.css */

body {
  font-family: "Roboto", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f7fa;
  /* color: #333; */
}

.payments-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  /* color: #222; */
  margin-bottom: 20px;
}

.payments-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.payments-table th,
.payments-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.payments-table th {
  background-color: #3f51b5;
  font-weight: bold;
}

.payments-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.action-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}

.view-all-button {
  display: block;
  margin: 0 auto;
  background-color: #28a745;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.view-all-button:hover {
  background-color: #218838;
}
