/* Profile.css */

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.account-settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Section Styles */
.settings-section {
  margin-bottom: 30px;
}

.settings-section h2 {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
}

.settings-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.settings-item label {
  font-weight: bold;
  color: #444;
}

.settings-item span {
  color: #666;
}

/* List Styles */
.list-group {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  margin: 8px 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.list-item:hover {
  background-color: #f0f8ff;
}

.delete-button {
  background-color: #ff4d4d;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

/* Input Group */
.input-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.input-field:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.add-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.add-button:hover {
  background-color: #0056b3;
}

/* Empty State */
.settings-section p {
  color: #999;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 600px) {
  .settings-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-group {
    flex-direction: column;
    gap: 5px;
  }
}
