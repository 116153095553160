/* Global Styles */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

.container {
  padding: 20px;
}

/* Header Section */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  gap: 20px;
  font-size: 16px;
  font-weight: bold;
}

.tabs span {
  padding-bottom: 5px;
  cursor: pointer;
}

.tabs span.active {
  border-bottom: 2px solid #000;
}

.tabs button {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.tabs button:hover {
  background-color: #e2e6ea;
}

/* Search and Action Section */
.actions {
  display: flex;
  gap: 20px;
  align-items: center;
}

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #fff;
  width: 250px;
}

.search-bar input {
  border: none;
  outline: none;
  width: 100%;
  color: #333;
}

.search-bar i {
  margin-right: 5px;
  color: #888;
}

.dropdown {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  cursor: pointer;
  width: 170px;
  text-align: center;
}

.create-trip {
  background-color: #3f51b5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-trip:hover {
  background-color: #303f9f;
}

/* Table Section */
.table-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #f9f9f9;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

.table-row {
  text-align: center;
  padding: 20px;
  color: #888;
}

/* Download Reports Section */
.download-reports {
  text-align: right;
  margin-top: 10px;
}

.download-reports span {
  margin-right: 10px;
  cursor: pointer;
  color: #007bff;
}

.edit-btn,
.delete-btn {
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}
.edit-btn {
  background-color: blue;
}
.delete-btn {
  background-color: red;
}

.download-reports i {
  margin-right: 10px;
  cursor: pointer;
}

.download-reports i.fa-file-pdf {
  color: #d9534f;
}

.download-reports i.fa-file-excel {
  color: #5cb85c;
}

/* Create Trip Form Styles */
.create-trip-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.create-trip-form form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.close-form {
  background: #ff4747;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="datetime-local"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.create-trip-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
}

.create-trip-btn:hover {
  background-color: #45a049;
}

/* Responsiveness */
@media (max-width: 768px) {
  .header,
  .actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .search-bar {
    width: 100%;
  }

  .dropdown,
  .create-trip {
    width: 100%;
    text-align: center;
  }

  .table-header {
    grid-template-columns: repeat(2, 1fr);
  }

  .create-trip-form form {
    padding: 15px;
  }
}
