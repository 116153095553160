/* Layout for the left sidebar and map */
.route-optimization-layout {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

/* Left Sidebar */
.route-optimization-container {
  width: 30%;
  padding: 20px;
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.route-optimization-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Styling for the form groups */
.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.location-input,
.vehicle-select,
.datetime-picker,
.fuel-type-select,
.fuel-price-input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white; /* Ensures no grey background */
}

.location-input:focus,
.vehicle-select:focus,
.datetime-picker:focus,
.fuel-type-select:focus,
.fuel-price-input:focus {
  border-color: #007bff; /* Highlight inputs when focused */
  outline: none;
}

/* Style for invalid inputs */
.location-input.error,
.vehicle-select.error,
.datetime-picker.error,
.fuel-type-select.error,
.fuel-price-input.error {
  border-color: #ff0000; /* Red border for error */
  background-color: #ffebeb; /* Light red background for error */
}

.error-message {
  color: #ff0000;
  font-size: 12px;
  margin-top: 5px;
}

/* Buttons */
.add-destination-btn,
.fuel-info-btn,
.submit-btn {
  padding: 10px 15px;
  font-size: 16px;
  background: linear-gradient(to right, #6dcff6, #00bcd4);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.add-destination-btn:hover,
.fuel-info-btn:hover,
.submit-btn:hover {
  background: #00a4cc;
}

/* Dropdown for Fuel Info */
.fuel-info-dropdown {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f8ff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.fuel-info-row {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

/* Right Side Map Section */
.map-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
}

.map-placeholder {
  width: 90%;
  height: 90%;
  background: #ddd;
  border: 1px dashed #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #666;
}
