.geofence-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff; /* Clean white for a global appeal */
  padding: 20px 40px; /* Increased padding for more spacious layout */
  border-bottom: 2px solid #dcdcdc; /* Lighter, subtle border */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  border-radius: 8px; /* Slight rounding for a modern look */
  margin-bottom: 20px; /* Spacing between header and content */
}

.back-button {
  background-color: #0073e6; /* Slightly brighter blue for contrast */
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  border-radius: 8px; /* More rounded corners */
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase; /* Clear, bold text */
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for button prominence */
}

.back-button:hover {
  background-color: #005bb5; /* Darker blue for hover effect */
  transform: translateY(-2px); /* Lift effect for interactivity */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow */
}

.back-button:active {
  background-color: #004494; /* Even darker blue for active state */
  transform: translateY(0); /* Reset lift effect */
}

.search-input {
  flex: 1;
  margin: 0 30px;
  padding: 14px;
  border: 1px solid #cccccc; /* Neutral border */
  border-radius: 8px;
  font-size: 16px;
  font-family: "Arial", sans-serif; /* Clean, modern font */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
  border-color: #0073e6; /* Blue border for focus */
  outline: none;
  box-shadow: 0 0 6px rgba(0, 115, 230, 0.4); /* Subtle glow for focus */
}

.actions {
  display: flex;
  gap: 20px; /* Wider spacing for better separation */
}

.bulk-upload,
.create-geofence {
  background-color: #0073e6; /* Bright blue for call-to-action */
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase; /* Emphasize buttons as actions */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.bulk-upload:hover,
.create-geofence:hover {
  background-color: #005bb5; /* Darker blue for hover */
  transform: translateY(-2px); /* Hover lift effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.bulk-upload:active,
.create-geofence:active {
  background-color: #004494; /* Even darker blue for active state */
  transform: translateY(0); /* Reset lift effect */
}

.bulk-upload:disabled,
.create-geofence:disabled {
  background-color: #e0e0e0; /* Muted gray for disabled state */
  color: #8c8c8c; /* Light gray text for disabled buttons */
  cursor: not-allowed;
  box-shadow: none;
}

.geofence-list {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  scrollbar-color: #6c757d #e9ecef;
}

.geofence-list::-webkit-scrollbar {
  height: 8px;
}

.geofence-list::-webkit-scrollbar-track {
  background: #e9ecef;
  border-radius: 10px;
}

.geofence-list::-webkit-scrollbar-thumb {
  background: #6c757d;
  border-radius: 10px;
}

.geofence-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 12px 20px;
  border-radius: 10px;
  min-width: 220px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.geofence-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.geofence-item h4 {
  margin: 0;
  font-size: 1rem;
  color: #007bff;
}

.geofence-item p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555;
}

@media (max-width: 768px) {
  .geofence-header {
    flex-wrap: wrap; /* Stack items for smaller screens */
    padding: 20px;
  }

  .search-input {
    margin: 15px 0;
    width: 100%; /* Full width on smaller screens */
  }

  .actions {
    gap: 10px; /* Reduce spacing for smaller screens */
    flex-wrap: wrap; /* Allow buttons to wrap */
    justify-content: center;
  }

  .bulk-upload,
  .create-geofence,
  .back-button {
    width: 100%; /* Full width buttons */
    text-align: center;
    padding: 14px;
  }
}
