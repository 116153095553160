.dashboard-container {
  padding: 20px;
}

.unscheduled-stoppage,
.list-view {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.table-header h2 {
  margin: 0;
}

.table-header button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.table-header button:hover {
  background-color: #45a049;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

tbody td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.grand-total td {
  font-weight: bold;
}
