/* Container */
.tyre-maintenance-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 1000px; /* Increased width for better visibility */
  margin: 0 auto;
  overflow: hidden; /* Prevent content overflow outside the container */
}

/* Titles */
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px;
}

/* Chart */
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 300px;
  height: 300px;
  max-width: 100%;
}

/* Table */
.table-container {
  overflow-x: auto; /* Allow horizontal scrolling */
  padding-bottom: 10px; /* Space for scroll bar */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  table-layout: fixed; /* Ensure columns stay within container */
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  overflow: hidden; /* Prevent content overflow */
  text-overflow: ellipsis; /* Truncate long text */
  white-space: nowrap; /* Prevent text wrapping */
  min-width: 120px; /* Maintain reasonable column size */
}

.data-table th {
  background-color: #4caf50;
  color: white;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tr:hover {
  background-color: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .data-table {
    font-size: 14px;
  }

  .data-table th,
  .data-table td {
    padding: 8px;
  }

  .data-table th:last-child,
  .data-table td:last-child {
    min-width: 150px; /* Ensure last column remains visible on small screens */
  }
}
