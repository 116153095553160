.dashboard-container {
  padding: 20px;
}

.daily-running-km-summary,
.list-view {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.table-header h2 {
  margin: 0;
}

.table-header button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.table-header button:hover {
  background-color: #45a049;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  background-color: #3f51b5;
  color: white;
  text-align: left;
  padding: 8px;
}

tbody td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.grand-total td {
  font-weight: bold;
  background-color: #f9f9f9;
}

.list-view table thead th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

tbody tr:hover {
  background-color: #f1f1f1;
}
