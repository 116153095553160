/* VeyzaSupport.css */

.support-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

.support-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 1rem;
}

.support-container p {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.required {
  color: red;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

textarea {
  resize: none;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 1rem;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.submit-btn,
.reset-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 48%;
  transition: background-color 0.3s ease;
}

.reset-btn {
  background-color: #f44336;
}

.submit-btn:hover {
  background-color: #45a049;
}

.reset-btn:hover {
  background-color: #d32f2f;
}

.ticket-table {
  width: 100%;
  margin-top: 2rem;
  border-collapse: collapse;
}

.ticket-table th,
.ticket-table td {
  padding: 10px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.ticket-table th {
  background-color: #4caf50;
  color: white;
}

.ticket-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ticket-table tr:hover {
  background-color: #e8f5e9;
}

.ticket-table td {
  font-size: 14px;
  color: #333;
}

.ticket-table td[colspan="4"] {
  font-style: italic;
  color: #888;
}

@media (max-width: 600px) {
  .form-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .submit-btn,
  .reset-btn {
    width: 100%;
  }
}
/* 
     Chatbot Styles (Updated)
     */

/* Chatbot Button */
.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  border: none;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease, background 0.3s ease;
}

.chatbot-button:hover {
  background: linear-gradient(135deg, #0056b3, #003d82);
  transform: scale(1.1);
}

/* Chatbot Modal */
.chatbot-modal {
  position: fixed;
  bottom: 85px;
  right: 20px;
  width: 350px;
  max-width: 90%;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: slideUp 0.3s ease-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Chatbot Header */
.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 12px 12px 0 0;
}

.chatbot-header button {
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.chatbot-header button:hover {
  color: #ffd700;
}

/* Chatbot Body */
.chatbot-body {
  padding: 15px;
  font-size: 14px;
  color: #333;
  height: 320px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
}

/* Chatbot Messages */
.chatbot-message {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  font-size: 14px;
}

.bot-message {
  background: #e9f5ff;
  align-self: flex-start;
  border-left: 4px solid #007bff;
  padding-left: 12px;
}

.user-message {
  background: #007bff;
  color: white;
  align-self: flex-end;
  border-right: 4px solid #0056b3;
  padding-right: 12px;
}

/* Chatbot Input */
.chatbot-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: white;
}

.chatbot-input textarea {
  flex-grow: 1;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  resize: none;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.chatbot-input textarea:focus {
  border-color: #007bff;
  outline: none;
}

.chatbot-input button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-left: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.chatbot-input button:hover {
  background: #0056b3;
}

/* Slide-Up Animation */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Optimization */
@media (max-width: 400px) {
  .chatbot-modal {
    width: 90%;
    right: 5%;
    bottom: 70px;
  }
}
