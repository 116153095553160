/* LEFT SIDEBAR */
.sidebar {
  width: 20vw;
  background-color: white;
  color: black;
  position: fixed;
  top: 80px; /* Adjust for header height */
  left: 0;
  bottom: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto; /* Enable independent scrolling for the sidebar */
  transition: transform 0.3s ease, width 0.3s ease;
}

.sidebar.hidden {
  transform: translateX(-100%); /* Hide the sidebar */
  width: 0; /* Remove width when hidden */
  opacity: 0;
}

.sidebar-menu {
  list-style: none;
  color: black;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  padding: 15px;
  cursor: pointer;
  font-size: 16px; /* Same font size as in your original CSS */
  font-weight: 500; /* Same font weight as in your original CSS */
  transition: background-color 0.3s ease;
}

/* Hover Effect for Sidebar Items */
.sidebar-item:hover {
  background-color: rgba(0, 123, 255, 0.411);
}

/* Active State for Sidebar Items */
.sidebar-item.active {
  color: black; /* Ensure text remains visible */
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  border: none;
  color: black;
  border-radius: 4px;
}

.menu-item.active {
  background-color: #007bff;
  color: white;
}

.sidebar-dropdown-menu {
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
  padding-left: 10px;
}

.sidebar-dropdown-item {
  padding: 5px 0;
  cursor: pointer;
  color: #555;
}

.sidebar-dropdown-item:hover {
  color: #007bff;
}

/* Show/Hide Button */
.toggle-button {
  position: fixed;
  top: 80px; /* Align the button to the top of the sidebar */
  left: 20vw; /* Position button to the right of the sidebar */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2000;
  transition: left 0.3s ease; /* Smooth transition when hiding/showing sidebar */
}

.toggle-button.move-left {
  left: 0; /* Move button to the left when sidebar is hidden */
}

/* Responsive Sidebar */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    top: 60px; /* Adjust for smaller screen header height */
  }
}
