/* General Styling */
body {
  font-family: "Roboto", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa;
  color: #333;
}
/* 
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
} */

/* h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #007bff; 
}
*/

.search-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.search-box {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.search-box:focus {
  border-color: #007bff;
}

/* Custom Dropdown */
.time-range-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  font-size: 1rem;
}

.time-range-container label {
  font-size: 1rem;
  color: #333;
}

#time-range {
  padding: 12px 16px;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 220px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

#time-range:focus {
  border-color: #007bff;
  background-color: #eaf3ff;
}

#time-range option {
  padding: 12px;
  font-size: 1rem;
}

/* Custom Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.checkbox-container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #007bff;
  appearance: none;
  background-color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #0056b3;
}

.checkbox-container label {
  font-size: 1rem;
  color: #333;
}

/* Modal */
.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filter-modal-content {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.filter-modal-content h4 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 15px;
}

.filter-options {
  max-height: 300px;
  overflow-y: auto;
}

.filter-option {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.filter-option input {
  margin-right: 10px;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.apply-filter-btn,
.cancel-btn {
  padding: 12px 18px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.apply-filter-btn:hover,
.cancel-btn:hover {
  background-color: #0056b3;
}

/* Button Styles */
.filter-btn {
  padding: 10px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-btn:hover {
  opacity: 0.9;
}

.filter-btn:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
