/* css applied over complete app is written here */

* {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  box-sizing: inherit;
}

*::-webkit-scrollbar {
  display: none;
}
