/* Title Styling */
.coming-soon-title {
  font-size: 4rem; /* Bold, large text for prominence */
  font-weight: bold;
  margin: 0;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7); /* Subtle shadow for text */
}

/* Subtext Styling */
.coming-soon-subtext {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #4517dc; /* Softer white for subtext */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
