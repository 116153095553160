.vehicle-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.section {
  margin-bottom: 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.header-actions button {
  margin-right: 10px;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-actions button:hover {
  background-color: #eaeaea;
  border-color: #bbb;
}

.group-actions {
  display: flex;
  gap: 10px;
}

.group-actions .add-group,
.group-actions .bulk-upload {
  font-size: 12px;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.group-actions .add-group:hover,
.group-actions .bulk-upload:hover {
  background-color: #e0e0e0;
  border-color: #aaa;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table-header,
.table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.table-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.table-header span,
.table-row span {
  flex: 1;
  text-align: center;
}

.table-row span:last-child {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.table-body p {
  text-align: center;
  padding: 20px;
  color: #888;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  font-size: 14px;
  margin: 0 5px;
  padding: 6px 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #e9e9e9;
  border-color: #bbb;
}

h2 {
  font-size: 18px;
  /* color: #333; */
  margin: 0;
}

p {
  margin: 0;
}

/* Form Modal */
.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.group-form {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.group-form h3 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #888;
  outline: none;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-actions button[type="submit"] {
  background-color: #007bff;
  color: #fff;
}

.form-actions button[type="submit"]:hover {
  background-color: #0056b3;
}

.form-actions button[type="button"] {
  background-color: #e0e0e0;
}

.form-actions button[type="button"]:hover {
  background-color: #c7c7c7;
}
