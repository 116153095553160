/* Base styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f9fa;
  }
  
  .payout-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .payout-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .payout-button {
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .payout-button:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
  }
  
  .payout-button:active {
    background-color: #004080;
    transform: translateY(0);
  }
  