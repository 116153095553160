/* ManageSubscription.css */

.subscription-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  /* color: #333; */
  margin-bottom: 20px;
}

.filter-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.filter-section label {
  margin-right: 10px;
  font-size: 16px;
  color: #555555e8;
}

.filter-dropdown {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.subscription-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.subscription-table th,
.subscription-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.subscription-table th {
  background-color: #3f51b5;
  font-weight: bold;
}

.subscription-table tr:nth-child(even) {
  background-color: #f4f6f9;
}

.subscription-table tr:hover {
  background-color: #e9ecef;
}

.no-data {
  text-align: center;
  font-style: italic;
  color: #999;
}
