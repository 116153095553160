/* DASHBOARD HEADER */
.dashboard-navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  color: black;
}

.dashboard-navbar-logo img {
  height: 75px;
}

.dashboard-navbar-links {
  list-style: none;
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}

.dashboard-navbar-links li a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.dashboard-nav-button {
  background-color: transparent;
  border: none;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: 500;
}

.dashboard-nav-item {
  position: relative;
}

/* Dropdown Menu */
.dashboard-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: whitesmoke;
  color: black;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: none;
  min-width: 150px;
  z-index: 2000;
}

.dashboard-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.dashboard-dropdown-item:hover {
  background-color: #eaeaea;
}

.dashboard-nav-item:hover .dashboard-dropdown-menu {
  display: block;
}

/* Dropdown Arrow */
.dashboard-dropdown-arrow {
  margin-left: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

.dashboard-nav-item:hover .dashboard-dropdown-arrow {
  transform: rotate(-135deg);
}

/* LEFT SIDEBAR */
.sidebar {
  width: 20vw;
  background-color: white;
  color: black;
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.sidebar-menu {
  list-style: none;
  color: black;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  padding: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.sidebar-item:hover {
  background-color: rgba(0, 123, 255, 0.411);
}

.sidebar-item.active {
  background-color: #007bff;
  color: white;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  border: none;
  color: black;
  border-radius: 4px;
}

.menu-item.active {
  background-color: #007bff;
  color: white;
}

.sidebar-dropdown-menu {
  list-style: none;
  margin: 5px 0 0;
  padding: 0 0 0 10px;
}

.sidebar-dropdown-item {
  padding: 5px 0;
  cursor: pointer;
  color: #555;
}

.sidebar-dropdown-item:hover {
  color: #007bff;
}

/*Toggle Button*/
/* Style for the toggle button */
.toggle-button {
  position: absolute; /* Allows precise positioning */
  top: 1rem; /* Adjusts vertical placement */
  left: 0.5rem; /* Adjusts horizontal placement */
  z-index: 10; /* Ensures it appears above other elements */
  background-color: #4caf50; /* Green background color */
  border: none; /* Removes default border */
  border-radius: 50%; /* Makes the button circular */
  width: 40px; /* Button width */
  height: 40px; /* Button height */
  display: flex; /* Aligns icon inside */
  align-items: center; /* Centers icon vertically */
  justify-content: center; /* Centers icon horizontally */
  cursor: pointer; /* Changes cursor to pointer on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/* Add hover effect */
.toggle-button:hover {
  background-color: #388e3c; /* Darker green on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

/* Add focus outline for accessibility */
.toggle-button:focus {
  outline: 2px solid #80deea; /* Custom outline color */
  outline-offset: 2px; /* Adds spacing around the outline */
}

/* Move button slightly left if sidebar is hidden */
.toggle-button.move-left {
  left: -20px; /* Adjust based on your layout */
  background-color: #d32f2f; /* Red color to indicate toggle */
}

/* Add hover effect for the 'move-left' state */
.toggle-button.move-left:hover {
  background-color: #b71c1c; /* Darker red on hover */
}

/* MAIN LAYOUT */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
}

.dashboard-body {
  display: flex;
  flex: 1;
  justify-content: space-between;
  position: relative;
  padding: 0;
  /* padding-bottom: 60px; */
  transition: all 0.3s ease;
}

.dashboard-body.sidebar-visible .dashboard-content {
  margin-left: 20vw;
  padding: 0;
}

.dashboard-body.sidebar-hidden .dashboard-content {
  margin-left: 0;
}

.dashboard-content {
  flex-grow: 1;
  min-height: 100vh;
  background-color: #f5f5f5;
  overflow-y: auto;
  padding: 20px;
  transition: margin-left 0.3s ease;
}

.main-content h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Custom Scrollbar */
.dashboard-content::-webkit-scrollbar {
  width: 8px;
}

.dashboard-content::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 4px;
}

.dashboard-content::-webkit-scrollbar-thumb:hover {
  background-color: #a6a6a6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    top: 60px;
  }

  .dashboard-body.sidebar-visible .dashboard-content {
    margin-left: 0;
  }

  .dashboard-content {
    padding-left: 200px;
  }

  .dashboard-dropdown-menu {
    position: relative;
    top: 0;
    right: 0;
    min-width: unset;
    box-shadow: none;
    padding: 5px 0;
  }

  .dashboard-dropdown-item {
    padding: 10px 15px;
    font-size: 16px;
  }
}
