.vehicle-document-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.chart-card {
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.chart-card:hover {
  transform: scale(1.03);
}

.chart-card h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 8px;
}

.data-table th,
.data-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th {
  background: #0044cc;
  color: #fff;
  text-transform: uppercase;
}

.data-table tr:nth-child(even) {
  background: #f9f9f9;
}

.data-table tr:hover {
  background: #f1f1f1;
}
