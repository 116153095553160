/* General container */
.reports-container {
  padding: 40px;
  background-color: #f4f7fa;
  font-family: "Roboto", sans-serif;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header */
.reports-container h1 {
  font-size: 32px;
  font-weight: 700;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

/* Search Bar */
.search-bar {
  display: flex;
  justify-content: center; /* Center-align content */
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 20px; /* Space between items */
  max-width: 800px;
  margin: 0 auto;
  padding: 20px; /* Add padding to ensure buttons stay inside */
  background-color: #fff; /* Optional: Add background for better visibility */
  border: 1px solid #ddd; /* Optional: Add border for clarity */
  border-radius: 10px; /* Rounded corners */
}

.search-bar input {
  flex: 1; /* Allow input to take available space */
  min-width: 200px; /* Prevent input from shrinking too much */
  padding: 12px 20px;
  border-radius: 25px;
  border: 1px solid #ddd;
  font-size: 16px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.search-bar input:focus {
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}

/* Buttons for Automated Reports and Custom Report */
.search-bar button {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-bar .automated-reports-btn {
  background-color: #28a745;
  color: white;
}

.search-bar .new-custom-report-btn {
  background-color: #3f51b5;
  color: white;
}

.search-bar button:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.search-bar button:active {
  transform: scale(0.98);
}

.search-bar button .icon {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  margin-right: 8px;
}

/* Dropdown Styles */
.dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
  width: 350px;
  top: 100px;
  right: 20px;
  padding: 15px;
  z-index: 100;
}

/* Dropdown Header */
.dropdown-header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
}

/* Dropdown List */
.dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 14px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
}

/* Footer buttons in the dropdown */
.dropdown-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.apply-btn,
.reset-btn {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.apply-btn:hover,
.reset-btn:hover {
  background-color: #3f51b5;
}

.apply-btn:active,
.reset-btn:active {
  transform: scale(0.98);
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-bar {
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 10px;
  }

  .search-bar input {
    width: 100%;
  }

  .search-bar button {
    width: 100%;
  }

  .dropdown {
    top: 80px;
    right: 10px;
    width: 90%;
  }
}
